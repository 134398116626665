import SEO from '@/components/SEO'

export default function NotFound() {
  return (
    <div>
      <SEO title="Not found" />

      <p>Temos um problema. Você está perdido?</p>
    </div>
  )
}
